import styled from 'styled-components';

import { LAYOUT } from '../../constants';

const { MOBILE_RESOLUTION, TABLET_RESOLUTION } = LAYOUT;

export const Circle = styled.div`
  position: absolute;
  width: 503px;
  height: 509px;
  left: -80px;
  top: -126px;
  z-index: -1;

  background: radial-gradient(
    33.1% 32.33% at 50.1% 53.63%,
    #ab3737 0%,
    transparent 100%
  );

  ${TABLET_RESOLUTION} {
    left: 66px;
    top: -232px;
  }

  ${MOBILE_RESOLUTION} {
    height: 300px;
    left: -10px;
    top: -55px;
    width: 300px;
  }
`;
Circle.displayName = 'Circle';

export const Container = styled.section`
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 120px 0;

  ${TABLET_RESOLUTION} {
    align-items: center;
    flex-direction: column;
    padding: 120px 16px 0;
    text-align: center;
  }

  ${MOBILE_RESOLUTION} {
    align-items: center;
    flex-direction: column;
    padding-top: 60px;
    text-align: center;
  }
`;
Container.displayName = 'Container';

export const Description = styled.p`
  color: #8894a4;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.02em;
  line-height: 24px;
  margin: 0px;
  max-width: 647px;

  ${MOBILE_RESOLUTION} {
    min-width: 312px;
  }
`;
Description.displayName = 'Description';

export const Image = styled.img`
  left: 25px;
  position: relative;
  top: 18px;

  ${TABLET_RESOLUTION} {
    left: 180px;
    top: -100px;
  }

  ${MOBILE_RESOLUTION} {
    left: 0;
    top: 0;
    width: 300px;
  }
`;
Image.displayName = 'Image';

export const ImageContainer = styled.div`
  position: relative;
`;
ImageContainer.displayName = 'ImageContainer';

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  right: 75px;
  top: -30px;

  ${MOBILE_RESOLUTION} {
    align-items: center;
    margin: 0 0 32px;
    left: 0;
    top: 0;
  }
`;
LeftSide.displayName = 'LeftSide';

export const SignUp = styled.button`
  align-items: center;
  background: #ab3737;
  border: 0;
  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(6, 6, 6, 0.25);
  color: #f8f7ff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: 56px;
  justify-content: center;
  letter-spacing: 0.04em;
  line-height: 24px;
  margin: 40px 0 0;
  text-decoration: none;
  width: 199px;

  ${MOBILE_RESOLUTION} {
    width: 312px;
  }
`;
SignUp.displayName = 'SignUp';

export const Title = styled.h1`
  color: #f8f7ff;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  letter-spacing: 0.04em;
  line-height: 80px;
  margin: 0 0 24px 0;
  max-width: 695px;
  text-transform: uppercase;

  ${MOBILE_RESOLUTION} {
    min-width: 312px;
  }

  & strong {
    color: #ab3737;
  }
`;
Title.displayName = 'Title';
