import React from 'react';
import i18n from 'i18n-js';

import InstagramSvg from '../../assets/icons/instagram.svg';
import TiktokSvg from '../../assets/icons/tiktok.svg';
import TwitterSvg from '../../assets/icons/twitter.svg';
import LogoSvg from '../../assets/icons/Imagotipo.svg';

import { Contact, Container, Copyright, Legal, LinkItem, Social } from './styles';

function Footer() {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <Container>
      <Legal>
        <LinkItem to="/terms-and-conditions">{i18n.t('screens.home.footer.terms')}</LinkItem>
        <LinkItem to="/privacy">{i18n.t('screens.home.footer.privacy')}</LinkItem>
        <LinkItem to="/cookies">{i18n.t('screens.home.footer.cookies')}</LinkItem>
        <LinkItem id="open_preferences_center">{i18n.t('screens.home.footer.cookiePreferences')}</LinkItem>
      </Legal>
      <Social>
        <Contact href="https://twitter.com/overlayon" target="_blank">
          <img src={TwitterSvg} alt="Twitter" />
        </Contact>
        <Contact href="https://www.instagram.com/overlayon" target="_blank">
          <img src={InstagramSvg} alt="Instagram" />
        </Contact>
        <Contact href="https://www.tiktok.com/@overlayon" target="_blank">
          <img src={TiktokSvg} alt="Tiktok" />
        </Contact>
      </Social>
      <img src={LogoSvg} alt="contadoresthegrefg.com" />
      <Copyright>{`© ${year} contadoresthegrefg.com`}</Copyright>
    </Container>
  );
}

export default Footer;
