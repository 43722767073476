import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AnalyticsProvider } from '../../providers';
import { Footer, Header } from '../../components';

import { Container, Subtitle, Text, Title, Wrapper } from './styles';

function Privacy() {
  const { pathname } = useLocation();

  useEffect(() => {
    AnalyticsProvider.hit('/privacy');
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />
      <Wrapper>
        <Container>
          <Title>PROTECCIÓN DE DATOS</Title>
          <Text>
            La presente Política de Privacidad regula tanto el tratamiento de
            datos de carácter personal que WWW.CONTADORESTHEGREFG.COM realiza en
            calidad de responsable del tratamiento, como el régimen de
            protección de datos aplicable a los servicios prestados por
            WWW.CONTADORESTHEGREFG.COM cuando actúa en calidad de encargada del
            tratamiento de los datos que los usuarios tratan en sus servicios.
          </Text>
          <Text>
            En ambos casos, tanto si WWW.CONTADORESTHEGREFG.COM actúa como
            responsable o como encargada del tratamiento, se cumple
            estrictamente con las exigencias de la normativa vigente en materia
            de protección de datos personales, y se mantiene un absoluto respeto
            por los datos y privacidad de los usuarios.
            <br />
            Esta Política es permanentemente accesible a través del enlace
            habilitado en el sitio web, denominado “Protección de datos”, y
            puede actualizarse en cualquier momento, bien como consecuencia de
            un cambio normativo o bien por modificarse la configuración de los
            servicios o el tipo de negocio desarrollado.
            <br />
            Las modificaciones que impliquen cambios en la prestación del
            servicio contratado, o requieran del consentimiento de los
            interesados, se comunicarán con una antelación de treinta días
            naturales para que los interesados puedan manifestar su oposición al
            tratamiento de sus datos según el cambio descrito, o para que los
            clientes puedan adoptar las medidas que estimen oportunas en
            relación con la modificación comunicada. Si transcurrido el plazo
            proporcionado el usuario accede de nuevo s sus servicios o no
            comunica su oposición, se entenderán aceptadas las nuevas
            condiciones.
          </Text>
          <Subtitle>1.Responsable del Tratamiento</Subtitle>
          <Text>
            WWW.CONTADORESTHEGREFG.COM trata los datos de forma leal y lícita;
            únicamente trata aquellos datos que considera adecuados, pertinentes
            y no excesivos en relación con el ámbito y las finalidades
            determinadas, explícitas y legítimas para las que los obtiene, y no
            los utiliza para finalidades incompatibles con aquellas para las que
            los datos han sido recogidos.
          </Text>
          <Subtitle>1.1.Veracidad</Subtitle>
          <Text>
            Los usuarios del sitio web WWW.CONTADORESTHEGREFG.COM (en adelante,
            los usuarios) son responsables de la veracidad y actualidad de los
            datos que proporcionan a WWW.CONTADORESTHEGREFG.COM, considerándose
            exactos y actuales los datos facilitados por ellos mismos. Los
            usuarios pueden ejercer sus derechos de acceso, rectificación,
            cancelación y oposición sobre sus datos personales en la forma
            establecida por la Ley, esto es, enviando su solicitud junto con una
            copia de su DNI por una de las siguientes vías:
            <br />
            Mediante correo electrónico a la dirección
            CONTADORESTHEGREFG@gmail.com.
          </Text>
          <Subtitle>1.2.Información</Subtitle>
          <Text>
            WWW.CONTADORESTHEGREFG.COM, en calidad de responsable del tratamiento,
            informa de que tanto los datos de carácter personal facilitados por
            los usuarios al registrarse como aquellos que proporcionen para
            contratar cualquiera de los servicios ofrecidos en
            WWW.CONTADORESTHEGREFG.COM o para resolver cualquier incidencia que se
            comunique por cualquiera de los medios de contacto disponibles
            (teléfono, chat o correo electrónico), se incorporan o pueden
            incorporarse a un fichero de su titularidad, debidamente inscrito en
            el Registro habilitado por la Agencia Española de Protección de
            Datos, y que serán tratados de conformidad con los términos
            previstos en esta Política de Privacidad.
            <br />
            Para acceder y/o navegar por el sitio web de
            WWW.CONTADORESTHEGREFG.COM no es necesario aportar ningún dato. El
            carácter obligatorio de la respuesta a las preguntas que se
            plantean, o de los datos que se solicitan a través del sitio web, se
            indica mediante la incorporación de un asterisco (*) a continuación
            de la pregunta o campo del formulario. En caso de no proporcionarse
            los datos requeridos no será posible prestar el servicio o
            funcionalidad solicitados.
            <br />
            WWW.CONTADORESTHEGREFG.COM no trata datos de menores de edad en ningún
            caso, puesto que únicamente se permite el registro como usuario y la
            contratación de servicios a través del sitio web
            WWW.CONTADORESTHEGREFG.COM a personas mayores de edad con plena
            capacidad para actuar en el tráfico jurídico.
          </Text>
          <Subtitle>1.3.Finalidades</Subtitle>
          <Text>
            WWW.CONTADORESTHEGREFG.COM únicamente tratará los datos personales
            proporcionados por los usuarios de conformidad con las finalidades
            descritas en esta Política y en las Condiciones Generales de
            Contratación de los servicios contratados por los usuarios, las
            cuales se enumeran con carácter meramente enunciativo a
            continuación:
            <ul>
              <li>
                Usuarios y Resellers: los datos recopilados por cualquier de los
                medios o canales de contacto identificados en los sitios web
                (incluidos, los chats o las llamadas telefónicas) se utilizan
                con la finalidad de gestionar la cuenta y servicios contratados
                por los usuarios, tanto a nivel técnico como a nivel contable,
                fiscal o administrativo, y/o para notificarles incidencias,
                novedades o cualquier información de interés sobre sus
                servicios.
              </li>
              <li>
                WWW.CONTADORESTHEGREFG.COM utiliza los datos de contacto
                proporcionados por los usuarios para enviarles, por medios
                electrónicos o no, información sobre las mejoras que va
                implementando en los servicios que estos han contratado, y para
                mantenerles informados sobre las novedades que considera que  
                 pueden ser de su interés (ofertas y promociones).
                WWW.CONTADORESTHEGREFG.COM es absolutamente contraria a la
                práctica del spamming: el usuario es quien  decide –y autoriza-
                si quiere recibir o no este tipo de comunicaciones, bien al
                registrarse como usuario en WWW.CONTADORESTHEGREFG.COM y por
                tanto,  aceptando las Condiciones Generales de
                WWW.CONTADORESTHEGREFG.COM y la presente Política, o bien al
                solicitar información de este tipo por cualquiera de los  medios
                de contacto previstos en la web.
              </li>
              <li>
                WWW.CONTADORESTHEGREFG.COM conserva los datos de tráfico y
                localización generados en el marco de la prestación de los
                servicios de comunicaciones  electrónicas contratados por los
                usuarios, a disposición de las autoridades competentes durante
                el plazo legalmente establecido, de conformidad con  la Ley
                25/2007, de 18 de octubre, de conservación de datos relativos a
                las comunicaciones electrónicas y a las redes públicas de
                comunicaciones.  Estos datos no se utilizan con fines de
                promoción comercial.
              </li>
              <li>
                Sugerencias, Quejas o Comentarios: los datos recabados se
                utilizan para atender las comunicaciones recibidas y
                proporcionarles la correspondiente respuesta
              </li>
              <li>
                Contacto: los datos recibidos por cualquiera de los medios y/o
                canales de contacto indicados en el sitio web, se utilizan para
                contactar y/o gestionar la solicitud o incidencia comunicada.
                Las llamadas recibidas pueden ser grabadas, por motivos de
                seguridad y para mejorar la calidad de los servicios.
              </li>
              <li>
                Las cookies que utiliza WWW.CONTADORESTHEGREFG.COM son anónimas y
                no proporcionan referencias que permitan deducir datos
                personales del usuario. Las únicas cookies no anónimas, es
                decir, que permiten la identificación del usuario, son aquellas
                que el usuario voluntariamente activa al acceder al “Descargas”
                del sitio web. Estas cookies almacenan el nombre de usuario en
                su equipo terminal, así como un hash del nombre del usuario y la
                contraseña, durante quince días, con la exclusiva finalidad de
                facilitar el acceso a las áreas restringidas al usuario sin
                necesidad de introducir manualmente las claves de acceso cada
                vez. Para conocer nuestra política de instalación y gestión de
                dispositivos de almacenamiento y recuperación  de datos en
                equipos terminales consulta nuestra Política de Cookies.
              </li>
              <li>
                Redes sociales: los usuarios que utilicen el servicio de
                publicación automatizada en redes sociales deberán proporcionar
                sus claves de acceso (nombre de usuario/ dirección de correo
                electrónico y contraseña) a dichas redes, las cuales serán
                utilizadas con la exclusiva finalidad de publicar en nombre de
                los usuarios los contenidos que estos proporcionen. Dichos
                contenidos deberán respetar tanto la legislación vigente como
                las condiciones de uso de las propias redes sociales.
              </li>
              <li>
                Otros formularios web (Patrocinios, nuevos TLD, etc.): la
                información se utiliza para la finalidad descrita en el propio
                formulario web.
              </li>
            </ul>
          </Text>
          <Subtitle>1.4.Consentimiento</Subtitle>
          <Text>
            Los usuarios consienten el tratamiento de sus datos en los términos
            anteriormente indicados mediante la aceptación de la presente
            Política de Privacidad al registrarse como usuario de
            WWW.CONTADORESTHEGREFG.COM, o al enviar información o utilizar
            cualquiera de los medios de contacto previstos en los sitios web
            WWW.CONTADORESTHEGREFG.COM, o al contratar el servicio específico que
            exija dicho tratamiento de conformidad con las Condiciones Generales
            de Contratación del servicio que corresponda. Los usuarios deben
            abstenerse de proporcionar datos de carácter personal de otros
            interesados, salvo que dispongan de la pertinente autorización,
            según la cual dichos interesados habrán sido previa y debidamente
            informados sobre el contenido de la presente Política de Privacidad
            y, en concreto, que consienten que sus datos sean facilitados a
            WWW.CONTADORESTHEGREFG.COM para ser tratados conforme a las
            finalidades que correspondan, así como que pueden ejercitar los
            derechos de acceso, rectificación, cancelación y oposición (en
            adelante, denominados conjuntamente ARCO) en los términos descritos
            en esta misma Política. En cualquier caso, WWW.CONTADORESTHEGREFG.COM
            cancelará los datos registrados en cuanto hayan dejado de ser
            necesarios o pertinentes para la finalidad para la que se recabaron,
            pudiendo conservarlos –debidamente bloqueados- durante el tiempo en
            que pueda exigirse algún tipo de responsabilidad derivada de una
            relación u obligación jurídica o de la ejecución de un contrato o de
            la aplicación de medidas precontractuales solicitadas por el
            interesado.
          </Text>
          <Subtitle>1.5.Cesión</Subtitle>
          <Text>
            WWW.CONTADORESTHEGREFG.COM no cede datos a terceros, salvo que una Ley
            o una norma comunitaria dispongan lo contrario, o si es necesario
            para la prestación del servicio contratado. En tal caso, únicamente
            comunica aquellos datos imprescindibles para gestionar la petición
            de los usuarios y prestar los servicios contratados, de forma que la
            cesión responde a la libre y legítima aceptación de una relación
            jurídica existente entre los interesados y WWW.CONTADORESTHEGREFG.COM,
            cuyo desarrollo, cumplimiento y control implican necesariamente la
            conexión de los datos y que incluye la transmisión necesaria de los
            mismos. En caso de que un usuario deje un comentario o interaccione
            socialmente con los sitios web de WWW.CONTADORESTHEGREFG.COM o en las
            redes sociales en las que esta está presente, debe tener presente
            que sus datos serán publicados en el entorno en el que actúe, es
            decir, estará autorizando expresamente la comunicación de sus datos
            -asociados a la acción que realiza- al resto de usuarios que acceden
            al sitio web o red social.
          </Text>
          <Subtitle>1.6.Revocabilidad</Subtitle>
          <Text>
            El consentimiento prestado, tanto para el tratamiento como para la
            cesión de los datos de los interesados, es revocable en cualquier
            momento comunicándolo a WWW.CONTADORESTHEGREFG.COM en los términos
            establecidos en esta Política para el ejercicio de los derechos
            ARCO. Esta revocación en ningún caso tendrá carácter retroactivo.
          </Text>
          <Subtitle>1.7.Derechos</Subtitle>
          <Text>
            Los interesados pueden ejercitar sus derechos ARCO, y revocar el
            consentimiento prestado para los tratamientos y/o cesiones de sus
            datos, en cualquier momento solicitándolo por correo postal dirigido
            al Departamento Jurídico de WWW.CONTADORESTHEGREFG.COM, en la
            siguiente dirección: CONTADORESTHEGREFG@gmail.com. En todo caso,
            deberá acompañarse la solicitud de una copia del documento oficial
            que acredite la identidad del titular de los datos.
          </Text>
          <Subtitle>2.Encargado del Tratamiento</Subtitle>
          <Text>
            No se considera cesión de datos, el acceso y/o tratamiento de datos
            de carácter personal que sean responsabilidad de los usuarios de
            WWW.CONTADORESTHEGREFG.COM cuando estos resulten necesarios para la
            adecuada prestación de los servicios que estos han contratado. En
            tales casos, WWW.CONTADORESTHEGREFG.COM tendrá la condición de
            encargado del tratamiento de conformidad con la normativa vigente en
            materia de protección de datos. El régimen de protección de datos
            aplicable a estos tratamientos se regula tanto en la presente
            Política, como en las Condiciones Generales de Contratación del
            Servicio correspondiente y en las Condiciones Particulares que
            configuran el pedido o solicitud final (denominadas en su conjunto,
            Contrato Principal).
          </Text>
          <Subtitle>2.1.Legitimación</Subtitle>
          <Text>
            El usuario que contrate el servicio (en adelante, persona
            Contratante), o en su caso la tercera entidad que decida sobre la
            finalidad, contendido, uso y tratamiento de los datos personales,
            son los únicos responsables de los datos que alojan en los servicios
            de WWW.CONTADORESTHEGREFG.COM.
            <br />
            WWW.CONTADORESTHEGREFG.COM trata la información alojada en sus
            servicios exclusivamente por cuenta de la persona Contratante en los
            términos y condiciones estipulados en el Contrato Principal. La
            persona Contratante, o la tercera entidad a la que esta le esté
            prestando un servicio tratando datos de los que aquella es
            responsable, manifiestan que son titulares de ficheros que contienen
            datos de carácter personal recabados legalmente y que, en virtud de
            los servicios contratados a WWW.CONTADORESTHEGREFG.COM, autoriza su
            tratamiento, en la medida que sea necesario para la prestación de
            estos.
          </Text>
          <Subtitle>2.2.Obligaciones</Subtitle>
          <Text>
            En aquellos casos en que, WWW.CONTADORESTHEGREFG.COM pudiera tener
            acceso a los datos, se compromete a no aplicar, utilizar, o revelar
            los datos tratados con fines distintos a los que se derivan del
            Contrato Principal.
            <br />
            WWW.CONTADORESTHEGREFG.COM únicamente tratará los datos contenidos en
            sus servicios para ejecutar los servicios contratados por cuenta de
            la persona Contratante en atención a las instrucciones que le
            indique y, en ningún caso, los utilizará para finalidades distintas
            a las acordadas en el Contrato Principal. WWW.CONTADORESTHEGREFG.COM
            no comunicará ni permitirá el acceso a los datos tratados a ningún
            tercero, ni siquiera para su conservación, salvo que: en el Contrato
            Principal se establezca lo contrario, o la comunicación sea
            necesaria para la prestación de los servicios contratados o que la
            cesión venga impuesta por una norma con rango de Ley. En este
            sentido, la persona contratante autoriza expresamente a
            WWW.CONTADORESTHEGREFG.COM, al contratar cualquiera de los servicios
            disponibles en los sitios web de WWW.CONTADORESTHEGREFG.COM, a
            subcontratar en su nombre y por su cuenta cuantas entidades sea
            necesario para la correcta prestación de los servicios detallados en
            el Contrato Principal. Las entidades así subcontratadas tendrán la
            condición de encargado del tratamiento, se verán sometidas a las
            mismas reglas de protección de datos y confidencialidad que
            WWW.CONTADORESTHEGREFG.COM y regularán su relación con
            WWW.CONTADORESTHEGREFG.COM de conformidad con la normativa sobre
            protección de datos. La identidad de estas entidades subcontratadas
            se publica en los sitios web de WWW.CONTADORESTHEGREFG.COM
          </Text>
          <Subtitle>2.3.Subcontratación</Subtitle>
          <Text>
            En caso que la persona Contratante actúe en calidad de encargado del
            tratamiento de una tercera entidad responsable de los datos debe
            garantizar antes de contratar cualquier servicio que implique el
            tratamiento de esos datos, que cuenta con la autorización expresa de
            esta para proceder a la subcontratación de los servicios que le
            hubiese encomendado y que coinciden con el objeto del Contrato
            Principal, asimismo asegura que la relación con la tercera entidad
            responsable de los datos se encuentra jurídicamente regulada
            conforme a las exigencias de la normativa vigente en materia de
            protección de datos con anterioridad a la contratación del servicio
            a través de WWW.CONTADORESTHEGREFG.COM. En caso contrario, deberá
            abstenerse de subcontratar con WWW.CONTADORESTHEGREFG.COM y si
            incumple esta prohibición será responsable y asumirá cualquier
            sanción que se imponga a la misma como consecuencia de esta ausencia
            de legitimación.
          </Text>
          <Subtitle>2.4.Seguridad</Subtitle>
          <Text>
            WWW.CONTADORESTHEGREFG.COM ha adoptado las medidas de índole técnica y
            organizativa necesarias para garantizar la seguridad de los datos de
            carácter personal y evitar su alteración, pérdida, tratamiento o
            acceso no autorizado, de conformidad con la normativa vigente en
            materia de protección de datos de carácter personal. Concretamente,
            las medidas asociadas al nivel de seguridad medio para ficheros y/o
            tratamientos automatizados, de conformidad con el Título VIII del
            Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el
            Reglamento de Desarrollo de la Ley Orgánica 15/1999, de 13 de
            diciembre, de Protección de Datos de carácter personal.
            WWW.CONTADORESTHEGREFG.COM únicamente proporciona la infraestructura
            técnica, y en caso de que así se contrate, la administración de
            esta, circunscribiéndose su responsabilidad a las medidas de
            seguridad proporcionadas a en relación con estas funciones. Por
            tanto, su responsabilidad se limitará a aquellas tareas que, por la
            propia naturaleza del Contrato Principal, deban ser llevadas a cabo
            por WWW.CONTADORESTHEGREFG.COM directamente en los servicios (tales
            como, por ejemplo, la restricción de acceso a los locales donde se
            ubican los recursos que alojan los datos). WWW.CONTADORESTHEGREFG.COM
            declina cualquier responsabilidad sobre la vulneración de los
            sistemas de seguridad de la persona Contratante o la inviolabilidad
            de la información cuando se transporta a través de cualquier red de
            comunicaciones. WWW.CONTADORESTHEGREFG.COM tampoco responderá de
            aquellas incidencias de seguridad que se produzcan como consecuencia
            de un ataque o acceso no autorizado a los sistemas de tal forma que
            resulte imposible detectarlo o impedirlo aun adoptándose las medidas
            necesarias según el estado de la tecnología actual, o bien de una
            falta de diligencia del usuario o persona Contratante en relación
            con la guardia y custodia de sus claves de acceso y/o datos
            personales. La persona Contratante reconoce que dichas medidas se
            ajustan al nivel de seguridad aplicable al tipo de información
            tratada como consecuencia de la prestación del servicio que
            WWW.CONTADORESTHEGREFG.COM realiza por cuenta de la persona
            Contratante, de conformidad con lo establecido en la normativa
            vigente en materia protección de datos. Corresponde, en exclusiva, a
            la persona Contratante valorar si las condiciones del Contrato
            Principal son adecuadas a sus necesidades y cumplen con los
            requerimientos legales a los que está obligado en calidad de
            Responsable del Fichero/ Tratamiento. En caso de que el tratamiento
            a realizar requiera de alguna medida adicional a las que se indican
            en el Contrato Principal, la persona Contratante deberá ponerlo en
            conocimiento de WWW.CONTADORESTHEGREFG.COM con el objeto de que pueda
            ofrecerle la posibilidad de contratar tecnologías y servicios
            adicionales necesarios para implementar dicha medida de seguridad.
            La contratación de la medida adicional se realizará mediante la
            formalización de un documento específico que se anexará al Contrato
            Principal. Si WWW.CONTADORESTHEGREFG.COM no pudiera proporcionar la
            medida adicional requerida por la persona Contratante se lo
            comunicará a este a la mayor brevedad posible. En caso de que para
            la prestación del servicio contratado se requiriera de una medida
            adicional no comunicada a WWW.CONTADORESTHEGREFG.COM o no contratada
            tras ofrecerle dicha opción a la persona Contratante,
            WWW.CONTADORESTHEGREFG.COM no responderá de la falta de implantación
            de las medidas de seguridad exigidas por la normativa vigente en
            materia de protección de datos. Del mismo modo, en caso de que la
            calificación del nivel del fichero tratado por
            WWW.CONTADORESTHEGREFG.COM para la prestación del servicio contratado
            fuera errónea, WWW.CONTADORESTHEGREFG.COM tampoco responderá de la
            falta de adecuación de las medidas de seguridad a lo dispuesto en la
            normativa.
            <br />
            La persona Contratante deberá notificar la identificación de los
            ficheros o tratamientos sobre los cuales WWW.CONTADORESTHEGREFG.COM
            sea encargado del tratamiento, indicando el nombre del fichero, su
            nivel o medidas de seguridad y los datos del Responsable del
            Fichero/Tratamiento, así como notificar cualquier cambio en los
            datos anteriores. En el caso de que la persona contratante
            incumpliera esta obligación, responderá de las sanciones que fueran
            impuestas a WWW.CONTADORESTHEGREFG.COM por tal motivo.
          </Text>
          <Subtitle>2.5.Conservación</Subtitle>
          <Text>
            WWW.CONTADORESTHEGREFG.COM conservará los datos de carácter personal a
            los que haya tenido acceso debido al servicio prestado, así como
            cualquier soporte o documento en el que consten, durante el tiempo
            en que esté vigente el Contrato Principal o mientras así lo disponga
            una Ley. Finalizado este, destruirá los datos y cualesquiera
            soportes o documentos en que estos se incorporen, teniendo en cuenta
            las características de los servicios contratados y los periodos de
            retención estipulados, en su caso, en el Contrato Principal. No
            obstante, WWW.CONTADORESTHEGREFG.COM podrá conservar los datos e
            información tratada, debidamente bloqueados, en el caso que pudieran
            derivarse responsabilidades de su relación con la persona
            Contratante. La destrucción de la información se realizará sin
            necesidad de emitir comunicación formal o certificación alguna en la
            que se manifieste que la misma ha sido llevada a cabo.
          </Text>
          <Subtitle>2.6.Confidencialidad</Subtitle>
          <Text>
            WWW.CONTADORESTHEGREFG.COM se compromete a mantener el secreto
            profesional respecto de los datos a los que acceda en virtud del
            Contrato Principal, y al deber de guardarlos, obligaciones que
            subsistirán aún después de finalizar su relación con la persona
            Contratante. El resto de las obligaciones previstas con relación al
            tratamiento de datos por cuenta de terceros, se extinguirán en el
            momento en que los datos hayan sido borrados o eliminados del equipo
            de almacenamiento de datos o de algún modo, destruidos o convertidos
            en inaccesibles.
          </Text>
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
}

export default Privacy;
