import React, { useState } from 'react';
import i18n from 'i18n-js';

import MenuSvg from '../../assets/icons/menu.svg';
import CloseSvg from '../../assets/icons/close.svg';
import LogoImg from '../../assets/main.svg';
import InstagramSvg from '../../assets/icons/instagram.svg';
import TiktokSvg from '../../assets/icons/tiktok.svg';
import TwitterSvg from '../../assets/icons/twitter.svg';

import { AnalyticsProvider } from '../../providers';
import { getAppUrl } from '../../helpers';

import {
  Contact,
  Container,
  FollowContainer,
  Logo,
  MenuContainer,
  MenuIcon,
  Navegation,
  NavegationMobile,
  TryNowLink,
  TitleLink,
} from './styles';

function Header() {
  const [menuVisible, setMenuVisible] = useState(false);

  const onClickTryNow = () => {
    const url = getAppUrl('dashboard');
    window.open(url, 'blank');
    AnalyticsProvider.event('CLICK_TRYNOW_HEADER');
  };

  return (
    <>
      <Container>
        <TitleLink to="/">
          <Logo alt="Logo" src={LogoImg} />
        </TitleLink>
        <Navegation>
          <TryNowLink onClick={onClickTryNow}>{i18n.t('menu.trynow')}</TryNowLink>
        </Navegation>
        <MenuIcon alt="Menu" onClick={() => setMenuVisible(!menuVisible)} src={menuVisible ? CloseSvg : MenuSvg} />
      </Container>
      <MenuContainer onClick={() => setMenuVisible(!menuVisible)} visible={menuVisible}>
        <NavegationMobile>
          <TryNowLink onClick={onClickTryNow}>{i18n.t('menu.trynow')}</TryNowLink>
        </NavegationMobile>
        <FollowContainer>
          <Contact href="https://twitter.com/overlayon" target="_blank">
            <img src={TwitterSvg} alt="Twitter" />
          </Contact>
          <Contact href="https://www.instagram.com/overlayon" target="_blank">
            <img src={InstagramSvg} alt="Instagram" />
          </Contact>
        <Contact href="https://www.tiktok.com/@overlayon" target="_blank">
          <img src={TiktokSvg} alt="Tiktok" />
        </Contact>
        </FollowContainer>
      </MenuContainer>
    </>
  );
}

export default Header;
