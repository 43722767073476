import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AnalyticsProvider } from '../../providers';
import { Footer, Header } from '../../components';

import { Container, Subtitle, Text, Title, Wrapper } from './styles';

function TermsAndConditions() {
  const { pathname } = useLocation();

  useEffect(() => {
    AnalyticsProvider.hit('/terms-and-conditions');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />
      <Wrapper>
        <Container>
          <Title>CONDICIONES GENERALES</Title>
          <Text>
            Mediante este documento se establecen las Condiciones Generales de
            Acceso y Uso del sitio web WWW.CONTADORESTHEGREFG.COM (en adelante,
            Condiciones), propiedad de Mikel García. El acceso y/o uso del sitio
            web de WWW.CONTADORESTHEGREFG.COM, por parte de los usuarios está
            condicionado a la previa lectura y aceptación íntegra, expresa y sin
            reservas de las presentes Condiciones vigentes en el momento del
            acceso. Si el usuario no estuviera de acuerdo con las presentes
            Condiciones, deberá abstenerse de utilizar este portal y de operar
            por medio de este. El usuario es plenamente consciente de que la
            mera navegación por la presente página web, así como la utilización
            de sus servicios, implica la aceptación de las presentes
            Condiciones.
          </Text>
          <Subtitle>
            1. Información y documentación contractual. Aceptación del contrato
          </Subtitle>
          <Text>
            Las presentes Condiciones Generales así como las Condiciones
            Generales de Contratación de los distintos servicios que se ponen a
            disposición del usuario en el sitio web WWW.CONTADORESTHEGREFG.COM, se
            exponen con carácter permanente en el sitio web
            WWW.CONTADORESTHEGREFG.COM (en adelante, “Sitio Web”), pudiendo todos
            los usuarios archivarlas, imprimirlas y, por tanto, estar
            previamente informados de las condiciones de acceso y uso del sitio,
            y de las condiciones de contratación bajo las cuales se prestarán
            los distintos servicios.
          </Text>
          <Text>
            Los usuarios al registrarse a través del formulario habilitado en el
            Sitio Web aceptan de forma expresa -mediante un clic de “Leo y
            acepto las Condiciones”- antes de enviar los datos, estas
            Condiciones Generales y la Política de Privacidad de
            WWW.CONTADORESTHEGREFG.COM. El usuario declara ser mayor de edad,
            tener capacidad suficiente para contratar y haber leído, entendido y
            aceptado las presentes Condiciones.
          </Text>
          <Text>
            En cuanto a la contratación de servicios por parte de los usuarios,
            al momento de producirse la misma y en un plazo no superior a las
            veinticuatro horas, WWW.CONTADORESTHEGREFG.COM, enviará a la dirección
            de correo electrónico, que el usuario contratante ha especificado en
            el formulario de registro de usuario como vía principal de
            CONTADORESTHEGREFG@gmail.com, la hoja de pedido o factura
            justificativa con descripción del servicio contratado o el coste
            -con desglose de impuestos si resultaran de aplicación- y período
            por el que se contrata. En el caso de compra del servicio, el envío
            de la factura justificativa implica la confirmación de se ha
            producido la contratación efectiva (no aplicable en la actualidad
            debido a la gratuidad del producto). Cualquier modificación
            posterior de las presentes Condiciones Generales o de las
            Condiciones Generales de Contratación de los servicios, será, por un
            lado, notificada al Contratante vía correo electrónico con quince
            días de antelación y, por otro, expuesta claramente en el Sitio Web.
            Toda la documentación señalada anteriormente puede ser impresa y
            archivada por la persona contratante, quien puede solicitarla en
            cualquier momento al Servicio de Atención al Cliente mediante la
            remisión del correspondiente correo electrónico a LA WEB.
          </Text>
          <Text>
            No es técnicamente posible que un usuario pueda finalizar una
            solicitud de contratación sin que se haya producido la aceptación de
            las presentes Condiciones Generales. Para que el usuario pueda
            efectuar esta aceptación y, por tanto, realizar la solicitud, debe
            estar registrado previamente como usuario en
            WWW.CONTADORESTHEGREFG.COM, cubriendo para ello un formulario con
            datos básicos cuyo envío implicará la concesión de una clave de
            usuario y una contraseña para acceder al área exclusiva del
            Contratante, y la aceptación de las presentes Condiciones.
          </Text>
          <Text>
            Cualquier solicitud de información, o reclamación que se estime
            pertinente, podrá plantearse al Servicio de Atención al Cliente en
            las señas identificadas en esta cláusula. El Servicio acusará recibo
            de la reclamación presentada mediante la remisión del pertinente
            justificante a la dirección de correo electrónico que deberá
            proporcionarse al Servicio de Atención al Cliente para tramitar la
            reclamación. Tras recibir una solicitud o reclamación por cualquier
            de los medios indicados en el párrafo anterior,
            WWW.CONTADORESTHEGREFG.COM, analizará el caso planteado por el usuario
            y le proporcionará una respuesta en la mayor brevedad posible, que
            en todo caso no superará los quince (15) días naturales contados a
            partir del día siguiente al de recibir NOMBRE REGISTRO MERCANTIL la
            solicitud o reclamación.
          </Text>
          <Subtitle>2. Objeto</Subtitle>
          <Text>
            El sitio web de WWW.CONTADORESTHEGREFG.COM, ha sido realizado para
            prestar servicios profesionales de APP por internet. Desde el sitio
            web de WWW.CONTADORESTHEGREFG.COM, los usuarios podrán contratar y
            gestionar los servicios que se ofrecen.
          </Text>
          <Subtitle>3. Condiciones de Acceso y Uso</Subtitle>
          <Text>
            La utilización del sitio web WWW.CONTADORESTHEGREFG.COM no conlleva la
            obligatoriedad de la inscripción o registro del usuario. Las
            condiciones de acceso y uso del sitio web WWW.CONTADORESTHEGREFG.COM
            se rigen estrictamente por la legalidad vigente y por el principio
            de buena fe comprometiéndose el usuario a realizar un buen uso del
            sitio web. Quedan prohibidos todos los actos que vulneren la
            legalidad, derechos o intereses de terceros, derecho a la intimidad
            y al honor, protección de datos, propiedad intelectual y en
            definitiva cualquier acción que pueda constituir una conducta
            ilícita. Expresamente y sin carácter taxativo,
            WWW.CONTADORESTHEGREFG.COM, prohíbe:
          </Text>
          <Text>
            Realizar acciones que puedan producir en el sitio web, o a través de
            este por cualquier medio, cualquier tipo de daño a los sistemas de
            WWW.CONTADORESTHEGREFG.COM o a terceros.
            <br />
            Realizar sin la debida autorización cualquier tipo de publicidad o
            información comercial directamente o de forma encubierta, el envío
            de correos masivos no solicitados (“spam”) o envío de grandes
            mensajes con el fin de bloquear servidores de la red (“mail
            bombing”). WWW.CONTADORESTHEGREFG.COM recibe (desde distintas
            organizaciones imparciales que luchan contra el uso de este tipo de
            prácticas) notificaciones cuando un usuario o persona contratante de
            WWW.CONTADORESTHEGREFG.COM envía masivamente correo no deseado bajo el
            nombre de algún producto contratado de WWW.CONTADORESTHEGREFG.COM. Por
            lo tanto, en caso de que WWW.CONTADORESTHEGREFG.COM reciba estas
            notificaciones se lo hará saber al usuario para que, con carácter
            inmediato cese con estas malas prácticas.
          </Text>
          <Text>
            WWW.CONTADORESTHEGREFG.COM no entrará a valorar si las direcciones a
            las que la persona contratante ha enviado correo no solicitado
            fueron obtenidas con el consentimiento de su titular sino que
            actuará siempre y cuando una organización dedicada a la lucha contra
            estas actividades así se lo notifique -estas organizaciones reciben
            las quejas de las personas que reciben correos no solicitados- y por
            tanto si la persona contratante no abandona el uso de estas
            prácticas, WWW.CONTADORESTHEGREFG.COM, cancelará de forma inmediata el
            servicio y resolverá el presente contrato reservándose el ejercicio
            de cuantas acciones legales sean oportunas para defender sus
            intereses.
            <br />
            Utilizar programas diseñados con el fin de causar problemas o
            ataques en la red.
            <br />
            Intentar poner a prueba la seguridad de los servidores realizando
            cualquier tipo de entrada o acción que no resulte estrictamente
            necesaria para el disfrute del servicio por parte del usuario.
            <br />
            WWW.CONTADORESTHEGREFG.COM podrá interrumpir en cualquier momento el
            acceso a su sitio web si detecta un uso contrario a la legalidad, la
            buena fe o a las presentes Condiciones generales- ver cláusula 7-.
          </Text>
          <Subtitle>4. Contenidos</Subtitle>
          <Text>
            Los contenidos incorporados en el sitio web de
            WWW.CONTADORESTHEGREFG.COM han sido elaborados e incluidos por:
          </Text>
          <Text>
            La propia entidad WWW.CONTADORESTHEGREFG.COM utilizando fuentes
            internas y externas, de tal modo que WWW.CONTADORESTHEGREFG.COM
            únicamente se hace responsable por los contenidos elaborados de
            forma interna.
          </Text>
          <Text>
            Los usuarios, a través de colaboraciones o introducción voluntaria
            de contenidos, siendo estos los únicos responsables de estos y
            quedando expresamente exenta WWW.CONTADORESTHEGREFG.COM de toda
            responsabilidad que se pueda derivar de los mismos.
          </Text>
          <Text>
            Personas físicas y jurídicas ajenas a WWW.CONTADORESTHEGREFG.COM, bien
            a través de colaboraciones que se insertan directamente en el sitio
            web, bien a través de enlaces o links, siendo estas las únicas
            responsables de los contenidos así introducidos y quedando
            expresamente exenta WWW.CONTADORESTHEGREFG.COM de toda responsabilidad
            en los términos que especifica la legislación española.
          </Text>
          <Text>
            Además, a través del sitio web de WWW.CONTADORESTHEGREFG.COM se ponen
            a disposición del usuario productos y servicios tanto de
            WWW.CONTADORESTHEGREFG.COM como de terceros destinados a ser
            comercializados y que estarán sujetos a las Condiciones Generales y
            particulares de cada uno de los mismos. WWW.CONTADORESTHEGREFG.COM no
            garantiza la veracidad, exactitud o actualidad de los contenidos
            relativos a los servicios ofrecidos por terceros ajenos y queda
            expresamente exenta de todo tipo de responsabilidad por los daños y
            perjuicios que puedan derivarse de la falta o errores en las
            características de los servicios que puedan ofrecer estos terceros.
          </Text>
          <Text>
            El usuario que desee establecer un hiperenlace en su sitio web al
            sitio web de WWW.CONTADORESTHEGREFG.COM no realizará un uso ilegal o
            contrario a la buena fe de las informaciones, servicios o productos
            puestos a disposición en el referido sitio web. Concretamente el
            usuario que introduzca un hiperenlace o link se compromete a:
          </Text>
          <Text>
            No destruir, dañar o alterar de cualquier forma los contenidos,
            servicios o productos puestos a disposición del usuario en el sitio
            web de WWW.CONTADORESTHEGREFG.COM.
          </Text>
          <Text>
            No declarar que WWW.CONTADORESTHEGREFG.COM asume la supervisión del
            hiperenlace o de los contenidos de la propia web del usuario que
            introduce un enlace al sitio web de WWW.CONTADORESTHEGREFG.COM en su
            propio sitio web. WWW.CONTADORESTHEGREFG.COM no será responsable en
            ningún caso de los contenidos o servicios de la web del usuario
            donde se incluya un hiperenlace o enlace al sitio web de
            WWW.CONTADORESTHEGREFG.COM.
          </Text>
          <Text>
            No incluir en su propio sitio web la marca, nombre comercial o
            cualquier signo distintivo perteneciente a WWW.CONTADORESTHEGREFG.COM
            sin la previa autorización de la entidad.
          </Text>
          <Text>
            La existencia de hiperenlaces en páginas web ajenas que permitan el
            acceso al portal de WWW.CONTADORESTHEGREFG.COM no implicará en ningún
            caso la existencia de relaciones comerciales o mercantiles con el/la
            titular de la página web donde se establezca el hiperenlace, ni la
            aceptación por parte de WWW.CONTADORESTHEGREFG.COM de sus contenidos o
            servicios.
          </Text>
          <Subtitle>5. Protección de Datos Personales</Subtitle>
          <Text>
            El régimen de protección de datos aplicable a los datos tratados por
            WWW.CONTADORESTHEGREFG.COM. se regula en la Política de Privacidad y
            en las Condiciones Generales de Contratación del Servicio de que se
            trate. El acceso y/o utilización del sitio web por parte de los
            usuarios del sitio web WWW.CONTADORESTHEGREFG.COM (en adelante, los
            usuarios) implica necesariamente la aceptación de dichos textos
            legales, consintiéndose el tratamiento de los datos en los términos
            descritos en ambos textos.
          </Text>
          <Text>
            WWW.CONTADORESTHEGREFG.COM trata los datos de forma leal y lícita,
            únicamente trata aquellos datos que considera adecuados, pertinentes
            y no excesivos en relación con el ámbito y las finalidades
            determinadas, explícitas y legítimas para las que los obtiene, y no
            los utiliza para finalidades incompatibles con aquellas para las que
            los datos han sido recogidos.
          </Text>
          <Text>
            Los usuarios son responsables de la veracidad y actualidad de los
            datos que proporcionan a WWW.CONTADORESTHEGREFG.COM, considerándose
            exactos y actuales los datos facilitados por ellos mismos.
          </Text>
          <Text>
            WWW.CONTADORESTHEGREFG.COM informa de que tanto los datos de carácter
            personal facilitados por los usuarios al registrarse como aquellos
            que proporcionen para contratar cualquiera de los servicios
            ofrecidos en WWW.CONTADORESTHEGREFG.COM o para resolver cualquier
            incidencia que se comunique por cualquiera de los medios de contacto
            disponibles (teléfono, chat o correo electrónico), se incorporan o
            pueden incorporarse a un fichero de su titularidad, debidamente
            inscrito en el Registro habilitado por la Agencia Española de
            Protección de Datos, y que serán tratados de conformidad con los
            términos previstos en esta Política de Privacidad.
          </Text>
          <Text>
            Para acceder y/o navegar por el sitio web de
            WWW.CONTADORESTHEGREFG.COM no es necesario aportar ningún dato. El
            carácter obligatorio de la respuesta a las preguntas que se
            plantean, o de los datos que se solicitan a través del sitio web, se
            indica mediante la incorporación de un asterisco (*) a continuación
            de la pregunta o campo del formulario. En caso de no proporcionarse
            los datos requeridos no será posible prestar el servicio o
            funcionalidad solicitados.
          </Text>
          <Text>
            Los usuarios deben abstenerse de proporcionar datos de carácter
            personal de otros interesados, salvo que dispongan de la pertinente
            autorización, según la cual dichos interesados habrán sido previa y
            debidamente informados sobre el contenido de la Política de
            Privacidad y, en concreto, que consienten que sus datos sean
            facilitados a WWW.CONTADORESTHEGREFG.COM para ser tratados conforme a
            las finalidades que correspondan, así como que pueden ejercitar los
            derechos de acceso, rectificación, cancelación y oposición (en
            adelante, denominados conjuntamente ARCO) en los términos descritos
            en dicha Política. En cualquier caso, WWW.CONTADORESTHEGREFG.COM,
            cancelará los datos registrados en cuanto hayan dejado de ser
            necesarios o pertinentes para la finalidad para la que se recabaron,
            pudiendo conservarlos –debidamente bloqueados- durante el tiempo en
            que pueda exigirse algún tipo de responsabilidad derivada de una
            relación u obligación jurídica o de la ejecución de un contrato o de
            la aplicación de medidas precontractuales solicitadas por el
            interesado.
          </Text>
          <Text>
            WWW.CONTADORESTHEGREFG.COM no cede datos a terceros, salvo que una Ley
            o una norma comunitaria dispongan lo contrario, o si es necesario
            para la prestación del servicio contratado. En tal caso, únicamente
            comunica aquellos datos imprescindibles para gestionar la petición
            de los usuarios y prestar los servicios contratados, de forma que la
            cesión responde a la libre y legítima aceptación de una relación
            jurídica existente entre los interesados y WWW.CONTADORESTHEGREFG.COM
            O, cuyo desarrollo, cumplimiento y control implican necesariamente
            la conexión de los datos y que incluye la transmisión necesaria de
            los mismos. En caso de que un usuario deje un comentario o
            interaccione socialmente con el sitio web de
            WWW.CONTADORESTHEGREFG.COM o en las redes sociales en las que esta
            está presente, debe tener presente que sus datos serán publicados en
            el entorno en el que actúe, es decir, estará autorizando
            expresamente la comunicación de sus datos -asociados a la acción que
            realiza- al resto de usuarios que acceden al sitio web o red social.
          </Text>
          <Text>
            Los interesados pueden ejercitar sus derechos ARCO, y revocar el
            consentimiento prestado para los tratamientos y/o cesiones de sus
            datos, en cualquier momento solicitándolo por correo electrónico
            dirigido a WWW.CONTADORESTHEGREFG.COM, en la siguiente dirección:
            CONTADORESTHEGREFG@gmail.com. En todo caso, deberá acompañarse la
            solicitud de una copia del documento oficial que acredite la
            identidad del titular de los datos.
          </Text>
          <Text>
            Para conocer nuestra política de instalación y gestión de
            dispositivos de almacenamiento y recuperación de datos en equipos
            terminales consulta nuestra Política de Cookies.
          </Text>
          <Text>
            Las condiciones de acceso y tratamiento de datos por parte de
            WWW.CONTADORESTHEGREFG.COM en calidad de encargado del tratamiento se
            regulan en la Política de Privacidad y en las Condiciones Generales
            de Contratación de los correspondientes servicios.
          </Text>
          <Subtitle>6. Responsabilidad de WWW.CONTADORESTHEGREFG.COM</Subtitle>
          <Text>
            WWW.CONTADORESTHEGREFG.COM no será responsable de los errores,
            retrasos en el acceso, retrasos en el sistema o cualquier anomalía
            que pueda surgir en relación con problemas de carácter general en la
            red Internet, causas de caso fortuito o fuerza mayor o cualquier
            otra contingencia totalmente imprevisible y por tanto ajena a la
            buena fe de la empresa. WWW.CONTADORESTHEGREFG.COM se compromete a
            tratar de solucionar estas incidencias poniendo todos los medios a
            su alcance y ofreciendo todo el apoyo necesario al usuario para
            solventar las incidencias de la forma más rápida y satisfactoria
            posible. Asimismo, WWW.CONTADORESTHEGREFG.COM no será responsable de
            los fallos que por estas causas se puedan producir en las
            comunicaciones, borrado o transiciones incompletas de manera que no
            garantiza que el sitio web esté en todo momento operativo cuando se
            deba a cuestiones no imputables a WWW.CONTADORESTHEGREFG.COM o que
            ésta no pueda resolver con los medios que están a su alcance. El
            usuario exime de toda responsabilidad a WWW.CONTADORESTHEGREFG.COM si
            alguna de estas causas comienza a producirse.
          </Text>
          <Text>
            WWW.CONTADORESTHEGREFG.COM no será responsable en ningún caso por los
            errores o daños producidos por el uso ineficiente y de mala fe del
            sitio web por parte del usuario. Tampoco será responsable
            WWW.CONTADORESTHEGREFG.COM de las consecuencias mayores o menores por
            la falta de comunicación entre WWW.CONTADORESTHEGREFG.COM y la persona
            contratante cuando sea imputable al no funcionamiento del correo
            electrónico facilitado o a la falsedad de los datos proporcionados
            por la persona contratante en su registro de usuario en
            WWW.CONTADORESTHEGREFG.COM.
          </Text>
          <Text>
            WWW.CONTADORESTHEGREFG.COM asume las responsabilidades que puedan
            derivarse de la prestación de los servicios que ofrece en los
            límites y tal y como se especifica en las distintas Condiciones
            Generales de Contratación de los servicios que se ponen a
            disposición del usuario.
          </Text>
          <Subtitle>7. Responsabilidad del Usuario</Subtitle>
          <Text>
            El acceso a nuestros sitios web se realiza bajo la propia y
            exclusiva responsabilidad del usuario, que responderá en todo caso
            de los daños y perjuicios que pueda causar a terceros o a
            WWW.CONTADORESTHEGREFG.COM. El usuario asume toda la responsabilidad
            derivada del uso de los Sitios Web, siendo el único responsable de
            todo efecto directo o indirecto que sobre la página web se derive,
            incluyendo, de forma enunciativa y no limitativa, todo resultado
            económico, técnico y/o jurídico adverso, así como la defraudación de
            las expectativas generadas por el Sitio Web, obligándose el usuario
            a mantener indemne a WWW.CONTADORESTHEGREFG.COM por cualesquiera
            reclamaciones derivadas, directa o indirectamente de tales hechos.
          </Text>
          <Text>
            El incumplimiento de las presentes Condiciones, así como el
            desarrollo de cualquier actividad ilícita o contraria a las
            Condiciones de Contratación de WWW.CONTADORESTHEGREFG.COM, podrá
            implicar la pérdida de la condición de usuario y, por tanto, el
            acceso al Panel de Control. En caso de perder la condición de
            usuario, WWW.CONTADORESTHEGREFG.COM, comunicará este hecho al usuario
            afectado con el fin de que proceda a la migración o traslado de
            servicios activos a otros proveedores dentro del plazo que se
            indicará al efecto. Transcurrido dicho plazo se procederá a dar de
            baja los servicios afectados en los términos descritos en las
            Condiciones Generales de Contratación del correspondiente servicio.
          </Text>
          <Subtitle>7.1. Buen uso del servicio</Subtitle>
          <Text>
            El usuario está obligado a hacer un buen uso del servicio y por
            tanto a no utilizarlo de mala fe para cualquier práctica que esté
            prohibida por la ley o rechazada por los usos mercantiles.
            WWW.CONTADORESTHEGREFG.COM queda facultada por medio de las presentes
            Condiciones Generales, a partir del momento en que tenga
            conocimiento fehaciente de la realización por parte del usuario de
            cualquier actuación o uso ilegal en general, a poner en conocimiento
            de las autoridades competentes estas circunstancias y dar de baja al
            usuario o restringirle el acceso a los sitios web o a los Servicios
            de WWW.CONTADORESTHEGREFG.COM. El usuario será el único responsable
            frente a cualquier reclamación o acción legal, judicial o
            extrajudicial iniciada por terceros perjudicados directamente por el
            usuario ante Juzgados u otros organismos, asumiendo este todos los
            gastos, costes e indemnizaciones. Asimismo,
            WWW.CONTADORESTHEGREFG.COM, colaborará y notificará a la autoridad
            competente estas incidencias en el momento en que tenga conocimiento
            fehaciente de que los daños ocasionados constituyan cualquier tipo
            de actividad ilícita, especialmente en el ámbito de contenidos
            introducidos por el usuario que puedan vulnerar derechos o intereses
            legítimos de WWW.CONTADORESTHEGREFG.COM o de terceros.
          </Text>
          <Subtitle>7.2. Conocimiento fehaciente</Subtitle>
          <Text>
            WWW.CONTADORESTHEGREFG.COM, en caso de recibir algún tipo de
            comunicado por parte de un tercero sobre la supuesta ilegalidad de
            algún contenido o actividad llevada a cabo por un usuario de los
            servicios de WWW.CONTADORESTHEGREFG.COM, y siempre y cuando esta
            comunicación sea recibida de modo que el remitente se identifique
            correctamente, procederá a ponerlo en conocimiento del usuario y, en
            caso de que la actividad sea manifiestamente ilícita, procederá a
            bloquear el servicio en cuestión. Para que esta actividad sea
            manifiestamente ilícita esta ilicitud debe ser inequívoca para
            cualquiera que acceda a esos contenidos. En casos concretos como
            derechos de autor o injurias y calumnias WWW.CONTADORESTHEGREFG.COM no
            puede entrar a dirimir si el usuario tiene o no derecho a mostrar
            esos contenidos o si los mismos son constitutivos de injurias o
            calumnias siendo únicamente la autoridad competente quien pueda
            indicar fehacientemente esa ilicitud y, por tanto, ordenar el cese
            del servicio.
          </Text>
          <Subtitle>7.3. Guardia y custodia de claves de acceso</Subtitle>
          <Text>
            El usuario será responsable de la seguridad y confidencialidad de
            todas las claves con las que accede a su área privada –que le son
            otorgadas al registrarse como usuario- para realizar la contratación
            de los distintos servicios debiendo guardarlas en lugar seguro con
            el fin de impedir el acceso a terceros no autorizados.
          </Text>
          <Subtitle>7.4. Diligencia debida</Subtitle>
          <Text>
            El usuario se responsabiliza de realizar todas las actuaciones que
            le sean requeridas con la diligencia debida. Con carácter especial,
            el usuario debe ser diligente en lo que se refiere a la
            actualización y veracidad de sus datos, fundamentalmente del correo
            electrónico señalado en el formulario de registro de usuario como
            vía principal de contacto con WWW.CONTADORESTHEGREFG.COM - ver
            cláusula 8-.
          </Text>
          <Subtitle>8. Comunicaciones</Subtitle>
          <Text>
            WWW.CONTADORESTHEGREFG.COM y el usuario acuerdan comunicarse y
            notificarse todas las incidencias que se produzcan a lo largo de la
            vigencia de los distintos servicios que pueda tener contratados,
            preferentemente y previo a cualquier otra vía de comunicación,
            mediante correo electrónico. El correo de WWW.CONTADORESTHEGREFG.COM
            para estas comunicaciones será CONTADORESTHEGREFG@gmail.com y el del
            usuario será el facilitado en el formulario de registro de usuario
            en WWW.CONTADORESTHEGREFG.COM, o en su defecto el utilizado para
            contactar con WWW.CONTADORESTHEGREFG.COM. El usuario se compromete a
            tener operativo este correo electrónico y a modificarlo desde su
            área privada si fuese necesario para continuar recibiendo las
            comunicaciones. En todo caso, si sucediese cualquier problema
            urgente o fallo en la anterior comunicación, las comunicaciones se
            realizarán mediante teléfono, correo postal, mensajería o cualquier
            otro sistema adecuado al fin que se persiga. Sin embargo, la vía de
            comunicación preferente es la del correo electrónico, quedando
            exenta WWW.CONTADORESTHEGREFG.COM de todo tipo de responsabilidad que
            se pueda derivar por la falta de consulta o error en el correo
            electrónico facilitado por el usuario. Cada una de las partes será
            responsable de la guarda y custodia de las copias de las
            comunicaciones que se realicen.
          </Text>
          <Subtitle>9. Jurisdicción y Ley Aplicable</Subtitle>
          <Text>
            Las presentes Condiciones Generales se rigen por la legislación
            española. Específicamente están sujetas a lo dispuesto en las
            siguientes normas: Ley 7/1988 de 13 de Abril, sobre Condiciones
            Generales de Contratación; Real Decreto Legislativo 1/2007, de 16 de
            noviembre, por el que se aprueba el texto refundido de la Ley
            General para la Defensa de los Consumidores y Usuarios y otras leyes
            complementarias; Ley Orgánica 15/1999 de 13 de Diciembre, sobre
            Protección de Datos de Carácter Personal; Ley 7/1996, de 15 de Enero
            de Ordenación del Comercio Minorista; Ley 34/2002, de 11 de Julio,
            de Servicios de la Sociedad de la Información y Comercio
            Electrónico; Ley 16/2009, de 13 de noviembre, de servicios de pago;
            así como cualquier otra norma que las desarrolle o modifique.
          </Text>
          <Text>
            Para la resolución de cualquier controversia o conflicto que se
            derive de las presentes Condiciones Generales serán competentes los
            Juzgados y Tribunales del sistema español, renunciando expresamente
            la persona contratante a cualquier otro fuero que pudiera
            corresponderle.
          </Text>
          <Subtitle>10. Miscelánea</Subtitle>
          <Text>
            En caso de que cualquier cláusula del presente documento sea
            declarada nula, las demás cláusulas seguirán vigentes y se
            interpretarán teniendo en cuenta la voluntad de las partes y la
            finalidad misma de las presentes condiciones. El presente contrato
            se realiza únicamente en español. NOMBRE WWW.CONTADORESTHEGREFG.COM
            podrá no ejercitar alguno de los derechos y facultades conferidos en
            este documento, lo que no implicará en ningún caso la renuncia a los
            mismos, salvo reconocimiento expreso por parte de
            WWW.CONTADORESTHEGREFG.COM. Las presentes Condiciones Generales se
            encuentran registradas en el Registro General de Condiciones de
            Contratación para otorgar transparencia y seguridad a todo el
            proceso.
          </Text>
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
}

export default TermsAndConditions;
