import React from 'react';
import i18n from 'i18n-js';

import MacImg from '../../assets/images/TheGrefg.webp';

import { AnalyticsProvider } from '../../providers';
import { getAppUrl } from '../../helpers';

import {
  Circle,
  Container,
  Description,
  Image,
  ImageContainer,
  LeftSide,
  SignUp,
  Title,
} from './styles';

function MainTheGrefg() {
  const onClickSignup = () => {
    const url = getAppUrl('dashboard');
    window.open(url, 'blank');
    AnalyticsProvider.event('CLICK_SIGNUP');
  };

  return (
    <Container>
      <LeftSide>
        <Title>
          <strong>{i18n.t('screens.home.main.title')}</strong>
          {i18n.t('screens.home.main.title1')}
        </Title>
        <Description>{i18n.t('screens.home.main.description')}</Description>
        <SignUp onClick={onClickSignup}>
          {i18n.t('screens.home.main.signup')}
        </SignUp>
      </LeftSide>
      <ImageContainer>
        <Circle />
        <Image src={MacImg} alt="app main TheGrefg view" />
      </ImageContainer>
    </Container>
  );
}

export default MainTheGrefg;
