import React from 'react';
import i18n from 'i18n-js';

import addToPhotosImg from '../../assets/icons/add_to_photos.svg';
import editRoadImg from '../../assets/icons/edit_road.svg';
import verifiedImg from '../../assets/icons/verified.svg';

import { AnalyticsProvider } from '../../providers';
import { getAppUrl } from '../../helpers';

import {
  Container,
  Description,
  Details,
  DetailsDescription,
  DetailsTitle,
  SignUp,
  Title,
} from './styles';

function HowItWorks() {
  const onClickSignup = () => {
    const url = getAppUrl('dashboard');
    window.open(url, 'blank');
    AnalyticsProvider.event('CLICK_SIGNUP');
  };

  return (
    <Container id="how-it-works">
      <Title>{i18n.t('screens.home.howItWorkd.title')}</Title>
      <Description>
        <Details>
          <img src={verifiedImg} alt="verified" />
          <DetailsTitle>
            {i18n.t('screens.home.howItWorkd.title1')}
          </DetailsTitle>
          <DetailsDescription>
            {i18n.t('screens.home.howItWorkd.description1')}
          </DetailsDescription>
        </Details>
        <Details>
          <img src={editRoadImg} alt="edit road" />
          <DetailsTitle>
            {i18n.t('screens.home.howItWorkd.title2')}
          </DetailsTitle>
          <DetailsDescription>
            {i18n.t('screens.home.howItWorkd.description2')}
          </DetailsDescription>
        </Details>
        <Details>
          <img src={addToPhotosImg} alt="Add to photos" />
          <DetailsTitle>
            {i18n.t('screens.home.howItWorkd.title3')}
          </DetailsTitle>
          <DetailsDescription>
            {i18n.t('screens.home.howItWorkd.description3')}
          </DetailsDescription>
        </Details>
      </Description>
      <SignUp onClick={onClickSignup}>
        {i18n.t('screens.home.howItWorkd.cta')}
      </SignUp>
    </Container>
  );
}

export default HowItWorks;
